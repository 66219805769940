"use client";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import Image from "next/image";
import img404 from "@/../public/images/abstract/404.png";
import { missingEN } from "@/assets/dictionaries/en/wip-content";
import { missingES } from "@/assets/dictionaries/es/wip-content";

const Custom404: React.FC = () => {
  const [missingContent, setMissingContent] = useState(missingEN); // Default to English

  useEffect(() => {
    // Get the language from localStorage
    const lang = localStorage.getItem("selectedOption") || "EN"; // Default to EN if not found

    // Set the content based on the language
    switch (lang.toUpperCase()) {
      case "ES":
        setMissingContent(missingES);
        break;
      case "EN":
      default:
        setMissingContent(missingEN);
        break;
    }
  }, []); // Only run on mount
  return (
    <section className="px-page-xl pt-16">
      <div className="py-16 flex flex-col gap-16 md:flex-row">
        <div className="flex flex-col w-full md:w-2/4 justify-center">
          <div className="text-4xl text-gbci-primary font-bold">
            {missingContent.missing_title}
          </div>
          <div className="text-3xl font-bold py-8">
            {missingContent.missing_desc_1}
          </div>
          <div className="text-3xl font-bold text-gbci-accent underline">
            <Link href="/">{missingContent.home_page}</Link>
          </div>
        </div>
        <div className="flex flex-col w-full md:w-2/4">
          <Image className="w-full" src={img404} alt="404 img" />
        </div>
      </div>
    </section>
  );
};

export default Custom404;
